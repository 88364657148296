"use strict";

const path = require('path');
const numeral = require('numeral');
const MODULE_NAME = 'rubyComponentPluginMediaManager';
const CMS_THUMBNAIL_TYPES = {
  LARGE_PADDED: 'large_padded',
  SMALL_PADDED: 'small_padded',
  LARGE: 'large',
  SMALL: 'small'
};
const ADDITIONAL_TYPES_OPTIONS = [{
  text: 'Vimeo',
  value: 'vimeo'
}, {
  text: 'YouTube',
  value: 'youtube'
}];
const META_TYPES_OPTIONS = [{
  text: 'Any Video',
  value: 'video'
}, {
  text: 'Any Image',
  value: 'image'
}];
const FILELESS_TYPES = ADDITIONAL_TYPES_OPTIONS.map(typeOption => typeOption.value);
const MAX_FILESIZE = 2 * 1024 * 1024 * 1024; // 2 GB

module.exports = {
  PLUGIN_NAME: MODULE_NAME,
  ACCEPTED_TYPES: ['image/*', 'audio/*', 'video/*', 'application/json' //# .json
  , 'application/pdf', 'application/msword', 'application/rtf', 'application/x-shockwave-flash' //# swf
  , 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.wordprocessingml.template', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.openxmlformats-officedocument.presentationml.slideshow', 'application/xml' //# .xml
  , 'text/calendar' //# .ics
  , 'text/csv' //# .csv
  , 'text/xml' //# .xml
  , 'application/zip' //# .zip
  , 'application/gzip' //# .gz
  , 'text/x-vcard' //# .vcf
  , 'text/plain' //# "txt","text","conf","def","list","log","in"
  , 'text/vtt' //# .vtt
  , 'application/x-subrip' //# .srt from mime-db
  , 'video/x-flv' //# .flv
  , 'font/*' //# .woff2, .woff, .ttf
  ],

  ACTIVITY_CONSTANTS: {
    verbByKeyword: {
      uploaded: 'Upload Media',
      replaced: 'Replace Media',
      derived: 'Derive Render',
      deleted_derived: 'Delete Derived Render'
    }
  },
  ADDITIONAL_TYPES_OPTIONS,
  BROWSER_SUPPORTED_IMG_TYPES: ['image/jpeg', 'image/gif', 'image/png', 'image/bmp', 'image/x-icon'],
  CHANGELOG_CONSTANTS: {
    action_keywords: {
      uploaded: 'uploaded',
      replaced: 'replaced',
      derived: 'derived',
      deleted_derived: 'deleted_derived'
    },
    verbByKeyword: {
      uploaded: 'Uploaded',
      replaced: 'File Replaced',
      derived: 'Derived Render',
      deleted_derived: 'Deleted Derived Render'
    }
  },
  COMPONENT_NAME: MODULE_NAME,
  CMS_THUMBNAIL_TYPES,
  CMS_THUMBNAIL_SPECS: {
    //# NOTE: thumbnail specs needs to be generic and irrespective of the coercion modules used
    //# because in some cases, we're accessing thumbnails remotely
    [CMS_THUMBNAIL_TYPES.LARGE_PADDED]: {
      width: 320,
      height: 320,
      pad_color: 'white',
      padded: true
    },
    [CMS_THUMBNAIL_TYPES.SMALL_PADDED]: {
      width: 160,
      height: 160,
      pad_color: 'white',
      padded: true
    },
    [CMS_THUMBNAIL_TYPES.LARGE]: {
      width: 320,
      height: 320
    },
    [CMS_THUMBNAIL_TYPES.SMALL]: {
      width: 160,
      height: 160
    }
  },
  DEFAULT_MEDIA_UPLOAD_CHUNK_SIZE: 32 * 1024 * 1024 // 32 MB
  ,
  ENDPOINTS: {
    REGENERATE_MEDIA: '/regenerate-media',
    REGENERATE_ALL_MEDIA_PROGRESS: '/regenerate-all-media-progress',
    RENDER_TYPE_OPTIONS: '/render-types'
  },
  FILELESS_TYPES,
  MAX_FILESIZE,
  MAX_FILESIZE_ERROR_MESSAGE_PAYLOAD: {
    title: 'Filesize Exceeded',
    message: `A file you're trying to upload exceeds the upload limit (${numeral(MAX_FILESIZE).format('0b')}). Please try compressing the file before uploading or contact support for assistance.`
  },
  DEFAULT_MIN_FILE_SIZE_FOR_RENDERING_COMPLETION_EMAIL: 10 * 1024 * 1024 // 10 MB
  ,
  DEFAULT_MIN_FILE_SIZE_FOR_PLACEHOLDER_VIDEO: 10 * 1024 * 1024 // 10MB
  ,
  PATH_TO_PLACEHOLDER_VIDEO: path.resolve(__dirname, './assets/VideoUploadPlaceholder.mp4'),
  MEDIA_TEMPLATE_KEYWORD: 'media',
  HASH_KEY: 'media',
  META_TYPES_OPTIONS,
  TYPES_TO_EXPAND: ['image', 'video', 'audio', 'text'],
  STREAMING_TYPES: ['vimeo', 'youtube']

  //# using `parse-duration` format
  ,
  SYS_EVENT_TIMEOUTS: {
    STARTED: '15min',
    CREATED: '1h'
  },
  MULTIPART_FORM_KEYS: {
    fileKey: 'file',
    dataKey: 'data'
  },
  TMP_DIR: 'tmp/media-manager' //# NOTE: This is relative to the generic-storage root
  ,
  UPLOAD_RETRY_CONFIRM_LIMIT: 20,
  UPLOAD_RETRY_WAIT_MS: 500
};